import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { EnvironmentEnum, LibConfigModule } from "@helloteaminc/front-common";
import { Angulartics2Module } from "angulartics2";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsModalService, ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CookieModule } from "ngx-cookie";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { HttpInterceptor } from "./core/http/http.interceptor";
import { AppConfigService } from "./core/services/app-config.service";
import { ImportService } from "./main/import/services/import.service";
import { TempDepartmentsService } from "./main/import/services/temp-departments.service";
import { TempEmploymentTypesService } from "./main/import/services/temp-emloyment-types.service";
import { TempLocationsService } from "./main/import/services/temp-location.service";
import { TempPositionsService } from "./main/import/services/temp-positions.service";
import { TempTerminationReasonsService } from "./main/import/services/temp-termination-reasons.service";
import { TempUsersService } from "./main/import/services/temp-users.service";
import { PositionsService } from "./main/organization/positions/services/positions.service";
import { TerminationReasonsService } from "./main/organization/termination-reasons/services/termination-reasons.service";
import { UserPaymentInfoService } from "./main/users/services/user-payment-info.service";
import { UsersService } from "./main/users/services/users.service";
import { ApplicationStylingDomService } from "./main/organization/organization-settings/application-styling/application-styling-dom.service";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";

const appInitializerFn = (appConfig: AppConfigService) => {
	return () => {
		return appConfig.loadAppConfig();
	};
};

const appInitializerSettingFn = (appConfig: AppConfigService) => {
	return () => {
		return appConfig.loadAppSettings();
	};
};

const httpInterceptorFactory = (
	config: AppConfigService,
	modalService: BsModalService,
	router: Router,
	http: HttpClient
) => {
	return new HttpInterceptor(http, modalService, router, config);
};

@NgModule({
	imports: [
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		CoreModule,
		Angulartics2Module.forRoot(),
		CookieModule.forRoot(),
		TooltipModule.forRoot(),
		PopoverModule.forRoot(),
		ModalModule.forRoot(),
		TabsModule.forRoot(),
		BsDatepickerModule.forRoot(),
		AppRoutingModule,
		LibConfigModule.forRoot({
			url: environment.apiUrl,
			environment: EnvironmentEnum.ORGANIZATION,
			production: environment.production,
			http: {
				provide: "HttpInterceptor",
				useFactory: httpInterceptorFactory,
				deps: [AppConfigService, BsModalService, Router, HttpClient],
			},
		}),
		AkitaNgDevtools.forRoot(),
	],
	declarations: [AppComponent],
	providers: [
		AppConfigService,
		ApplicationStylingDomService,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFn,
			multi: true,
			deps: [AppConfigService],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerSettingFn,
			multi: true,
			deps: [AppConfigService],
		},
		Title,
		UsersService,
		UserPaymentInfoService,
		PositionsService,
		ImportService,
		TempUsersService,
		TerminationReasonsService,
		TempTerminationReasonsService,
		TempDepartmentsService,
		TempPositionsService,
		TempLocationsService,
		TempEmploymentTypesService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
