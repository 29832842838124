import { Injectable } from '@angular/core';
import { DefaultSettings } from '@app/core/models/app-settings.model';
// import Color from 'color';
// import { AppStylingConfig } from '@tmc/tmos-core-ui';

@Injectable()
export class ApplicationStylingDomService {
  public static HT_FAVICON_ID = 'ht-favicon';
  public static HT_CUSTOM_CSS_ID = 'application-styling';

  constructor() {
    
  }

  updateStyling(appStyling: DefaultSettings): void {
    if (!!appStyling) {
      if (!!appStyling.images.app.favIcon) {
        this.removeExternalLinkElements();
        this.updateFavicon(appStyling.images.app.favIcon);
      }
      this.updateCss(appStyling);
    }
  }

  // I remove any favicon nodes that are not controlled by this service.
  private removeExternalLinkElements(): void {
    const linkElements = document.querySelectorAll('link[ rel ~= \'icon\' i]');
    for (const linkElement of Array.from(linkElements)) {
      linkElement.parentNode.removeChild(linkElement);
    }
  }

  // I inject the favicon element into the document header.
  private addFaviconNode(href: string): void {
    const linkElement = document.createElement('link');
    linkElement.setAttribute('id', ApplicationStylingDomService.HT_FAVICON_ID);
    linkElement.setAttribute('rel', 'icon');
    linkElement.setAttribute('type', 'image/png');
    linkElement.setAttribute('href', 'data:image/jpeg;base64,' + href);
    document.head.appendChild(linkElement);
  }

  // I remove the favicon node from the document header.
  private removeFaviconNode(): void {
    const linkElement = document.head.querySelector('#' + ApplicationStylingDomService.HT_FAVICON_ID);
    if (linkElement) {
      document.head.removeChild(linkElement);
    }
  }

  private updateFavicon(href: string): void {
    this.removeFaviconNode();
    this.addFaviconNode(href);
  }


  private updateCss(appStyling: DefaultSettings): void {
    const styleSheet = this.getCustomColorsStyleSheet();
    this.removeCssRules(styleSheet);
    this.addCssRules(styleSheet, appStyling);
  }

  private addCssRules(stylesheet: CSSGroupingRule, appStyling: DefaultSettings): void {
    stylesheet.insertRule(this.getRootColorProperties(appStyling), 0);
  }

  private getRootColorProperties(appStyling: DefaultSettings): string {
    return `:root {
    --header: ${appStyling.colors.header}!important;
    --action: ${appStyling.colors.general.action}!important;
    --blue: ${appStyling.colors.general.blue} !important;
    --action-hover: ${appStyling.colors.general.action_hover} !important;
    --secondary-text: ${appStyling.colors.general.secondary_text} !important;
    --affirmative: ${appStyling.colors.general.affirmative} !important;
    --warning: ${appStyling.colors.general.warning} !important;
    --danger: ${appStyling.colors.general.danger} !important;
    --informative: ${appStyling.colors.general.informative} !important;
    --elements-background: ${appStyling.colors.general.elements_background} !important;
    --background-blue: ${appStyling.colors.general.background_blue} !important;
    --suggestionBox: ${appStyling.colors.suggestionBox} !important;
  }`;
  }

  private removeCssRules(stylesheet: CSSGroupingRule): void {
    const length = stylesheet.cssRules ? stylesheet.cssRules.length : 0;
    for (let i = 0; i < length; i++) {
      stylesheet.deleteRule(0);
    }
  }

  private getCustomColorsStyleSheet(): CSSGroupingRule {
    for (let i = 0; i < document.styleSheets.length; i++) {
      const sheet = document.styleSheets[i];
      if (sheet.title === ApplicationStylingDomService.HT_CUSTOM_CSS_ID) {
        return sheet as any;
      }
    }
  }

}