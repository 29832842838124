import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfigModel } from "@app/core/models/app-config.model";
import { ApplicationStylingDomService } from "@app/main/organization/organization-settings/application-styling/application-styling-dom.service";
import { DefaultSettings } from "../models/app-settings.model";

@Injectable()
export class AppConfigService {
	private appConfig!: AppConfigModel;
	private appSettings!: DefaultSettings;

	constructor(
		private http: HttpClient,
		private applicationStylingDomService: ApplicationStylingDomService
	) {}

	loadAppConfig(): Promise<AppConfigModel | void> {
		return this.http
			.get<AppConfigModel>(`/appConfig.json?t=${new Date().getTime()}`)
			.toPromise()
			.then((data) => {
				const { intercomAppId, ...otherProperties } = data;
				const remainingProps = { ...otherProperties };

				if (!!this.appSettings) {
					this.appConfig = remainingProps;
					this.appConfig.intercomAppId =
						this.appSettings.intercomAppId;
					window.global["APP_ID"] = this.appConfig.intercomAppId;
				} else {
					this.appConfig = data;
				}
			});
	}

	loadAppSettings(): Promise<AppConfigModel | void> {
		return this.http
			.get(`/api/app-setting`)
			.toPromise()
			.then((data: any) => {
				if (!!data.length && data[0].configs) {
					this.applicationStylingDomService.updateStyling(
						data[0].configs
					);
					this.appSettings = data[0].configs;
					if (!!this.appConfig) {
						this.appConfig.intercomAppId =
							data[0].configs.intercomAppId;
						window.global["APP_ID"] = this.appConfig.intercomAppId;
					}
				}
			});
	}

	getConfig(): AppConfigModel {
		return this.appConfig;
	}

	getAppSettings(): DefaultSettings {
		return this.appSettings;
	}
}
