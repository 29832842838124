import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { enableAkitaProdMode, persistState } from "@datorama/akita";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

const storage = persistState({
	include: ["wizard-domain", "wizard"],
});

const providers = [{ provide: "persistStorage", useValue: storage }];

if (environment.production) {
	enableProdMode();
	enableAkitaProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule);
