import { DatetimeUtil } from './datetime.util';

export class CommonUtil {

	public static updateNavigation(): void {
		setTimeout(() => {
			let $nav = $('.navigation');
			$nav.find('li').has('ul').children('a').addClass('has-ul');
			$nav.find('li').not('.active, .category-title').has('ul').children('ul').addClass('hidden-ul');

			$('.navigation-main').find('li').has('ul').children('a').off('click')
				.on('click', function () {
					$(this).parent('li').not('.disabled').not(<any>$('.sidebar-xs').not('.sidebar-xs-indicator').find('.navigation-main').children('li')).toggleClass('active').children('ul').slideToggle(250);
				});
		}, 1);
	}

	public static guid(): string {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	public static getValueInBrackets(input: string): string {
		return input.match(/\(([^)]+)\)/)[1];
	}

	public static roundNumber(num: number): number {
		return Math.round(num * 100) / 100;
	}

	public static getPeriodRange(value: string): Date[] {
		let start;
		let end;

		switch (value) {
			case 'this-year': {
				start = DatetimeUtil.localNow().startOf('year');
				end = DatetimeUtil.localNow().endOf('year');
				break;
			}
			case 'last-year': {
				start = DatetimeUtil.localNow().subtract(1, 'year').startOf('year');
				end = DatetimeUtil.localNow().subtract(1, 'year').endOf('year');
				break;
			}
			case 'next-year': {
				start = DatetimeUtil.localNow().add(1, 'year').startOf('year');
				end = DatetimeUtil.localNow().add(1, 'year').endOf('year');
				break;
			}
			case 'this-semi-year': {
				const quarter = DatetimeUtil.localNow().quarter();
				if (quarter === 1 || quarter === 2) {
					start = DatetimeUtil.localNow().startOf('year');
					end = DatetimeUtil.localNow().month(5).endOf('month');
				} else {
					start = DatetimeUtil.localNow().month(6).startOf('month');
					end = DatetimeUtil.localNow().endOf('year');
				}
				break;
			}
			case 'last-semi-year': {
				const quarter = DatetimeUtil.localNow().quarter();
				if (quarter === 1 || quarter === 2) {
					start = DatetimeUtil.localNow().subtract(1, 'year').month(6).startOf('month');
					end = DatetimeUtil.localNow().subtract(1, 'year').endOf('year');
				} else {
					start = DatetimeUtil.localNow().startOf('year');
					end = DatetimeUtil.localNow().month(5).endOf('month');
				}
				break;
			}
			case 'next-semi-year': {
				const quarter = DatetimeUtil.localNow().quarter();
				if (quarter === 1 || quarter === 2) {
					start = DatetimeUtil.localNow().month(6).startOf('month');
					end = DatetimeUtil.localNow().endOf('year');
				} else {
					start = DatetimeUtil.localNow().add(1, 'year').startOf('year');
					end = DatetimeUtil.localNow().add(1, 'year').month(5).endOf('month');
				}
				break;
			}
			case 'this-quarter': {
				start = DatetimeUtil.localNow().startOf('quarter');
				end = DatetimeUtil.localNow().endOf('quarter');
				break;
			}
			case 'last-quarter': {
				start = DatetimeUtil.localNow().subtract(1, 'quarter').startOf('quarter');
				end = DatetimeUtil.localNow().subtract(1, 'quarter').endOf('quarter');
				break;
			}
			case 'next-quarter': {
				start = DatetimeUtil.localNow().add(1, 'quarter').startOf('quarter');
				end = DatetimeUtil.localNow().add(1, 'quarter').endOf('quarter');
				break;
			}
			case 'this-month': {
				start = DatetimeUtil.localNow().startOf('month');
				end = DatetimeUtil.localNow().endOf('month');
				break;
			}
			case 'last-month': {
				start = DatetimeUtil.localNow().subtract(1, 'month').startOf('month');
				end = DatetimeUtil.localNow().subtract(1, 'month').endOf('month');
				break;
			}
			case 'next-month': {
				start = DatetimeUtil.localNow().add(1, 'month').startOf('month');
				end = DatetimeUtil.localNow().add(1, 'month').endOf('month');
				break;
			}
			case 'this-week': {
				start = DatetimeUtil.localNow().startOf('isoWeek');
				end = DatetimeUtil.localNow().endOf('isoWeek');
				break;
			}
			case 'last-week': {
				start = DatetimeUtil.localNow().subtract(1, 'week').startOf('isoWeek');
				end = DatetimeUtil.localNow().subtract(1, 'week').endOf('isoWeek');
				break;
			}
			case 'next-week': {
				start = DatetimeUtil.localNow().add(1, 'week').startOf('isoWeek');
				end = DatetimeUtil.localNow().add(1, 'week').endOf('isoWeek');
				break;
			}
			case 'this-day': {
				start = DatetimeUtil.localNow().startOf('day');
				end = DatetimeUtil.localNow().endOf('day');
				break;
			}
			case 'last-day': {
				start = DatetimeUtil.localNow().subtract(1, 'day').startOf('day');
				end = DatetimeUtil.localNow().subtract(1, 'day').endOf('day');
				break;
			}
			case 'next-day': {
				start = DatetimeUtil.localNow().add(1, 'day').startOf('day');
				end = DatetimeUtil.localNow().add(1, 'day').endOf('day');
				break;
			}
			default: {
				return;
			}
		}

		return [start.toDate(), end.toDate()];
	}
}
