import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginResponse } from '../../auth/models/login-response.model';
import { HttpInterceptor } from '../../core/http/http.interceptor';
import { AppConfigService } from '../../core/services/app-config.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Picture } from '../../main/shared/models/picture.model';

@Injectable({
	providedIn: 'root'
})
export class SsoService {

	constructor(private injector: Injector) {
	}

	get http(): HttpInterceptor {
		return this.injector.get(HttpInterceptor);
	}

	get authenticationService(): AuthenticationService {
		return this.injector.get(AuthenticationService);
	}

	get config(): AppConfigService {
		return this.injector.get(AppConfigService);
	}

	mutateOrganizationRecords(organizations: any[], isPaychex?: boolean) {
		organizations.forEach(organization => {
			if (!organization.logo) {
				organization.logo = isPaychex ? '/assets/images/Paychexlogo.svg' : '/assets/images/logo.svg';
			} else {
				Picture.updatePicture(organization.logo);
				organization.logo = `https://app.${this.config.getConfig().domain}${organization.logo.small}`;
			}
		});

		return organizations;
	}

	organizations$(data: any): Observable<any> {
		const key = btoa(unescape(encodeURIComponent(`${data.email}:${data.password}`)));
		return this.http.post(this.getOrganizationsURL(), {}, ['authentication', key, data.tokenResponse]).pipe(
			map(response => {
				const organizations = response.body;
				return this.mutateOrganizationRecords(organizations);
			})
		);
	}

	getGenericLoginOptions$(): Observable<any> {
		return this.http.get(this.getGenericLoginOptionsListURL(), []).pipe(
			map(response => {
				return {
					...response.body,
					basic: true
				};
			})
		);
	}

	getOrganizationLoginOptions$(): Observable<any> {
		return forkJoin([
			this.getGenericLoginOptions$(),
			this.http.get(this.getOrganizationLoginOptionsURL(), []).pipe(
				map(response => response.body)
			)
		]).pipe(
			map(r => {
				const authOptionLinks = r[0];
				const organizationAuthOptions = r[1];
				const options = {};

				Object.keys(organizationAuthOptions).forEach(key => {
					if (organizationAuthOptions[key] && key !== 'organizationId') {
						options[key] = authOptionLinks[key];
					}
				});

				return options;
			})
		);
	}

	ssoOrganizationsToken$(token: string): Observable<any> {
		return this.http.post(this.getOrganizationsTokenURL(), { token }, []).pipe(
			map(response => {
				response.body.organizations = this.mutateOrganizationRecords(response.body.organizations, response.body.isPaychex);
				return response.body;
			})
		);
	}

	ssoAuth$(data: any): Observable<any> {
		const body = new FormData();
		body.append('username', data.email);
		body.append('password', data.password);
		body.append('grant_type', 'password');
		body.append('scope', 'read');
		body.append('organizationId', data.organizationId);

		return this.http.post(this.getOAuthTokenURL(), body, ['authorization']).pipe(
			map(response => response.body)
		);
	}

	refreshToken$(): Observable<LoginResponse> {
		const body = new FormData();
		body.append('grant_type', 'refresh_token');
		body.append('refresh_token', this.authenticationService.getRefreshToken());

		return this.http.post(this.getOAuthTokenURL(), body, ['authorization']).pipe(
			map(response => response.body)
		);
	}

	exchangeCode$(code: string): Observable<LoginResponse> {
		const payload = {
			token: code
		};

		return this.http.post(this.getTokenExchangeURL(), payload, []).pipe(
			map(response => response.body)
		);
	}

	exchangeCodeExternal$(token: string, organizationId: string, workerId?: string): Observable<LoginResponse> {
		return this.http.post(this.getTokenExchangeExternalURL(), { token, organizationId, workerId }, []).pipe(
			map(response => response.body)
		);
	}

	authWith$(token: string, email: string, organizationId: string): Observable<any> {
		return this.http.post(this.getAuthWithURL(), { email, organizationId }, ['auth-with', token]).pipe(
			map(response => response.body)
		);
	}

	impersonateUser$(email: string, organizationId: string): Observable<any> {
		return this.http.post(this.getImpersonateURL(), { email, organizationId }, []).pipe(
			map(response => response.body)
		);
	}

	private getBaseURL(): string {
		return '/sso';
	}

	private getOrganizationLoginOptionsURL(): string {
		return `${this.getBaseURL()}/organization/login-options`;
	}

	private getOrganizationsURL(): string {
		return `${this.getBaseURL()}/organizations`;
	}

	private getOAuthTokenURL(): string {
		return `${this.getBaseURL()}/oauth/token`;
	}

	private getGenericLoginOptionsListURL(): string {
		return `${this.getBaseURL()}/list`;
	}

	private getOrganizationsTokenURL(): string {
		return `${this.getOrganizationsURL()}/token`;
	}

	private getTokenExchangeURL(): string {
		return `${this.getOAuthTokenURL()}/exchange`;
	}

	private getTokenExchangeExternalURL(): string {
		return `${this.getTokenExchangeURL()}/external`;
	}

	private getAuthWithURL(): string {
		return `${this.getBaseURL()}/oauth/auth-with`;
	}

	private getImpersonateURL(): string {
		return `${this.getBaseURL()}/oauth/impersonate-user`
	}
}
