import { AccountPreference } from '@app/auth/models/account-preference.model';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { UserBadgeAssignment } from '../../badges/models/user-badge-assignment.model';
import { Hobby } from '../../organization/hobbies/models/hobby.model';
import { Position } from '../../organization/positions/models/position.model';
import { Skill } from '../../organization/skills-ranks-page/skills/models/skill.model';
import { UserGroup } from '../../organization/user-groups/models/user-group.model';
import { Role } from '../../permissions/models/role.model';
import { Conference } from '../../professional-profile/user-desired-conferences/models/conference.model';
import { Training } from '../../professional-profile/user-desired-trainings/models/training.model';
import { Education } from '../../professional-profile/user-education/models/education.model';
import { ResponsibilityTag } from '../../professional-profile/user-responsibilities/models/responsibility-tag.model';
import { Responsibility } from '../../professional-profile/user-responsibilities/models/responsibility.model';
import { WorkExperience } from '../../professional-profile/user-work-experience/models/work-experience.model';
import { Picture } from '../../shared/models/picture.model';
import { BaseUser } from '../../users/models/base-user.model';
import { Anniversary } from '../interfaces/anniversary.interface';
import { CustomField } from '../interfaces/custom-field.interface';
import { VerificationDetails } from '../interfaces/verification-details.interface';
import { JobChange } from '../users-job-changes/models/job-change.model';
import { Tenure } from './tenure.model';
import { UserPaymentInfo } from './user-payment-info.model';
import { UserSocialLinks } from './user-social-links.model';

export class User extends BaseUser {

	id: string;
	email: string;
	organizationId: string;
	flags: string[];
	info: {
		terms: {
			accepted: Date;
			version: string;
		}
	};
	salary: {
		decimalAmount: number;
		amount: number;
		currencyType: string;
		prefix: string;
		displayString: string;
		salary?: UserPaymentInfo;
	};

	groups: UserGroup[];

	preference: AccountPreference;

	components: string[];
	roles: Role[];

	subordinateIds: string[];
	description: string;
	socialLinks: UserSocialLinks;
	hobbies: Hobby[];
	skills: Skill[];
	badges: UserBadgeAssignment[];
	education: Education[];
	experience: WorkExperience[];
	responsibilities: Responsibility;
	responsibilityTags: ResponsibilityTag[];
	desiredPositions: Position[];
	desiredSkills: Skill[];
	desiredConferences: Conference[];
	desiredTrainings: Training[];
	birthday: Date | string;
	anniversary: Anniversary;
	changeGroup: {
		groupId: string;
		ownerId: string;
		createdAt: Date;
		changes: JobChange[]
	};
	hfCount: number;
	hfLeft: number;
	totalHighFivePerPeriod: number;
	highFiveDetails?: {
		hfLeft: number;
		hfPeriodLimit: number;
		hfGiven: number;
	};

	verificationDetails: VerificationDetails;
	isActive: boolean;
	hasIRewardsIntegration: boolean;
	customFields: CustomField[];
	userActive: boolean;
	timeZone: string;
	disabled?: boolean; // Used for the ng-select
	active: boolean;
	employeeId?: string;

	diffs?: {
		field: string;
		from: any;
		to: any;
	}[];

	constructor() {
		super();
		this.socialLinks = new UserSocialLinks();
		this.preference = new AccountPreference();
		this.highFiveDetails = {
			hfPeriodLimit: undefined,
			hfLeft: undefined,
			hfGiven: undefined
		};
	}

	public static updatePicture(user: BaseUser): void {

		if (!user) {
			return;
		}

		if (!user.profilePicture) {
			user.profilePicture = Picture.getDefault();
			return;
		}

		if (user.profilePicture.built) {
			return;
		}

		let url = environment.shareFolderPath;

		let pic = user.profilePicture;

		pic.large = url + pic.large;
		pic.small = url + pic.small;
		pic.tiny = url + pic.tiny;
		pic.built = true;
	}

	public static buildUsersPictureUrls(users: any[]): void {
		if (!users) {
			return;
		}

		users.forEach(user => User.updatePicture(user));
	}

	public static reformatTenure(user: BaseUser) {
		user.tenureDisplay = Tenure.reformatTenure(user.tenure);
	}

	public static reformatTenures(users: BaseUser[]): void {
		if (!users) {
			return;
		}

		users.forEach(u => {
			User.reformatTenure(u);
		});
	}

	public static calculateAnniversary(users: User[]): { lastMonth: User[], thisMonth: User[], nextMonth: User[] } {
		let result = { lastMonth: [], thisMonth: [], nextMonth: [] };

		if (!users || !users.length) {
			return result;
		}

		const currentMonth = moment().month();

		for (let user of users) {
			const dateHiredMonth = moment(user.dateHired).month();

			let yearsOfAnniversary = moment().year() - moment(user.dateHired).year();
			if (dateHiredMonth < currentMonth) {
				yearsOfAnniversary++;
			}

			user.anniversary = {
				yearsOfAnniversary: yearsOfAnniversary === 1 ? '1 year' : yearsOfAnniversary + ' years',
				anniversaryDate: moment(user.dateHired).format('DD MMMM')
			};

			if (moment().month() === moment(user.dateHired).month()) {
				result.thisMonth.push(user);
			} else if (moment().subtract(1, 'months').month() === moment(user.dateHired).month()) {
				result.lastMonth.push(user);
			} else {
				result.nextMonth.push(user);
			}
		}

		return result;
	}

	public static calculateBirthdays(users: User[]): { lastMonth: User[], thisMonth: User[], nextMonth: User[] } {
		let result = { lastMonth: [], thisMonth: [], nextMonth: [] };

		if (!users || !users.length) {
			return result;
		}

		for (let user of users) {
			if (moment().month() === moment(user.birthday).month()) {
				result.thisMonth.push(user);
			} else if (moment().subtract(1, 'months').month() === moment(user.birthday).month()) {
				result.lastMonth.push(user);
			} else {
				result.nextMonth.push(user);
			}
		}

		return result;
	}

	static reformatBirthday(user: User) {
		user.birthday = moment(user.birthday, environment.apiDateFormat).format('DD MMMM');
		const now = moment().format('DD MMMM');
		const tomorrow = moment().add(1, 'day').format('DD MMMM');

		if (user.birthday === now) {
			user.birthday = 'Today';
		}

		if (user.birthday === tomorrow) {
			user.birthday = 'Tomorrow';
		}
	}

	static reformatBirthdays(users: User[]): void {
		if (!users) {
			return;
		}

		users.forEach(u => {
			User.reformatBirthday(u);
		});
	}
}
