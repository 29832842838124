import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { InitialSetupDoneGuard } from "@app/core/http/guards/initial-setup-done.guard";
import { InitialSetupNotCompletedGuard } from "@app/core/http/guards/initial-setup-not-completed.guard";
import { MeGuard } from "@app/core/http/guards/me.guard";
import { PositionsResolver } from "@app/core/resolvers/positions.resolver";
import { AuthGuard } from "./core/http/guards/auth.guard";
import { NonAuthenticatedGuard } from "./core/http/guards/non-authenticated.guard";
import { SubordinatesGuard } from "./core/http/guards/subordinates.guard";
import { DepartmentsResolver } from "./core/resolvers/departments.resolver";
import { EmploymentTypesResolver } from "./core/resolvers/employment-types.resolver";
import { LocationsResolver } from "./core/resolvers/locations.resolver";
import { PendoConfigResolver } from "./core/resolvers/pendo-config.resolver";
import { TeamsResolver } from "./core/resolvers/teams.resolver";
import { TimezonesResolver } from "./core/resolvers/timezones.resolver";

const routes: Routes = [
	{
		path: "auth",
		loadChildren: () =>
			import("./auth/auth.module").then((m) => m.AuthModule),
		canLoad: [NonAuthenticatedGuard],
	},
	{
		path: "register",
		loadChildren: () =>
			import("./register/register.module").then((m) => m.RegisterModule),
		canLoad: [NonAuthenticatedGuard],
	},
	{
		path: "orgchart-sharing",
		loadChildren: () =>
			import("./org-chart-share/org-chart-share.module").then(
				(m) => m.OrgChartShareModule
			),
	},
	{
		path: "startup",
		loadChildren: () =>
			import("./startup/startup.module").then((m) => m.StartupModule),
		canLoad: [AuthGuard, InitialSetupNotCompletedGuard, MeGuard],
	},
	{
		path: "lms",
		loadChildren: () =>
			import("./lms-loading/lms-loading.module").then(
				(m) => m.LmsLoadingModule
			),
		canLoad: [AuthGuard, MeGuard],
	},
	{
		path: "external-surveys",

		loadChildren: () =>
			import("./external/external.module").then((m) => m.ExternalModule),
	},
	{
		path: 'reviews-ui',
		loadChildren: ()=> import('@app/main/queries/performance-reviews-v3/reviews.module').then(m=> m.ReviewsModule)
	},
	{
		path: "v3reviews-wizard", // to escape from ingress
		loadChildren: () => import("@app/main/queries/performance-reviews-v3/wizard/wizard.module").then((m) => m.WizardModule),
		canLoad: [AuthGuard],
	},
	{
		path: "",
		loadChildren: () =>
			import("./main/main.module").then((m) => m.MainModule),
		canLoad: [AuthGuard, InitialSetupDoneGuard, MeGuard, SubordinatesGuard],
		resolve: {
			departments: DepartmentsResolver,
			positions: PositionsResolver,
			locations: LocationsResolver,
			teams: TeamsResolver,
			employmentTypes: EmploymentTypesResolver,
			pendoConfig: PendoConfigResolver,
			timezones: TimezonesResolver,
		},
	},
	{
		path: "**",
		redirectTo: "/dashboard",
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
