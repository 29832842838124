import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@app/core/http/http.interceptor';
import { PagedResourceInterface } from '@app/core/interfaces/paged-resource.interface';
import { RequestOptions } from '@app/core/interfaces/request-options.interface';
import { CrudService } from '@app/core/services/crud.service';
import { Observable } from 'rxjs';
import { map, pluck, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Department } from '../../organization/departments/models/department.model';
import { ReportsToUser } from '../../shared/components/org-chart/models/reports-to-user.model';
import { CustomField } from '../interfaces/custom-field.interface';
import { User } from '../models/user.model';

@Injectable()
export class UsersService extends CrudService<User, RequestOptions<User>> {

	constructor(http: HttpInterceptor) {
		super(http, '/users');
	}

	public getValidReportsToUri(): string {
		return this.getUri() + '/valid-reports-to';
	}

	public getAll(options: RequestOptions<User>, suffix?: string): Observable<HttpResponse<PagedResourceInterface<User>>> {
		return super.getAll(options, suffix)
			.pipe(
				map((response) => {
					if (options.expand && options.expand.indexOf('department') > -1) {
						Department.generateDisplayNames(response.body.content.map(x => x.department));
						Department.generateHtmlDisplayNames(response.body.content.map(x => x.department));
					}

					User.buildUsersPictureUrls(response.body.content);

					return response;
				})
			);
	}

	public getValidReports(params?: { userId?: string, sFields?: string, sVal?: string }): Observable<ReportsToUser[]> {
		return this.http.get(this.buildUrl({getParams: params}, '/valid-reports-to'), ['auth']).pipe(
			map((response: HttpResponse<ReportsToUser[]>) => {
				return response.body.map(user => {
					return {
						...user,
						nameAndEmail: user.name + ' (' + user.email + ')',
						disabled: !user.validity.valid
					};
				});
			}));
	}

	public getAllWithManageReviewsPrivilege(options: RequestOptions<User>): Observable<HttpResponse<PagedResourceInterface<User>>> {
		if (!options.size) {
			options.size = 99999;
		}

		return this.http.get(this.buildUrl(options, '/search/suggest/performance-review'), ['auth'])
			.pipe(
				tap((response: HttpResponse<PagedResourceInterface<User>>) => {
					User.buildUsersPictureUrls(response.body.content);
				})
			);
	}

	public getById(options: RequestOptions<User>): Observable<HttpResponse<User>> {
		return super.getById(options)
			.pipe(
				map((response) => {
					this.mutateExpands(response.body as any);

					if (options.expand && options.expand.indexOf('department') > -1) {
						Department.generateDisplayName(response.body.department);
						Department.generateHtmlDisplayName(response.body.department);
					}

					User.updatePicture(response.body);

					return response;
				})
			);
	}

	public getByIds(options: RequestOptions<any>): Observable<HttpResponse<PagedResourceInterface<User>>> {
		options.getParams = options.getParams || {};
		if (!options.getParams.lookUpConfigs) {
			options.getParams.lookUpConfigs = false;
		}

		return this.http.post(this.buildUrl(options, '/list-ids'), options.data, ['auth'])
			.pipe(
				map((response) => {
					this.mutateExpands(response.body as any);

					response.body.content.forEach((employee: User) => {
						Department.generateDisplayName(employee.department);
						Department.generateHtmlDisplayName(employee.department);
						User.updatePicture(employee);
					});

					return response;
				})
			);
	}

	public saveDottedAbove(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.put(this.buildUrl(options, '/dotted-lines/above'), options.data, ['auth']);
	}

	public saveDottedBelow(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.put(this.buildUrl(options, '/dotted-lines/below'), options.data, ['auth']);
	}

	save(options: RequestOptions<User>): Observable<HttpResponse<any>> {
		const data: any = options.data;

		if (data.department && (typeof data.department) !== 'string') {
			data.department = data.department.id;
		}
		if (data.position && (typeof data.position) !== 'string') {
			data.position = data.position.id;
		}
		if (data.location && (typeof data.location) !== 'string') {
			data.location = data.location.id;
		}
		if (data.reportsTo && (typeof data.reportsTo) !== 'string') {
			data.reportsTo = data.reportsTo.id;
		}

		return super.save(options);
	}

	getInitialProfile$(): Observable<{ admin: User, head: User }> {
		return this.http.get(this.buildUrl({}, '/get-initial-profile'), ['auth']).pipe(
			map(response => response.body)
		);
	}

	setupInitialProfile$(admin: User, head: User, isAdminHead: boolean): Observable<any> {
		const data = {
			admin: admin,
			...!isAdminHead && {head: head},
			adminHead: isAdminHead
		};

		return this.http.post(this.buildUrl({}, '/setup-initial-profile'), data, ['auth']);
	}

	public getActiveUsers(options: RequestOptions<User>): Observable<HttpResponse<PagedResourceInterface<User>>> {
		if (!options.size) {
			options.size = 99999;
		}

		options.getParams = options.getParams || {};
		if (!options.getParams.lookUpConfigs) {
			options.getParams.lookUpConfigs = false;
		}

		return this.http.get(this.buildUrl(options, '/active'), ['auth']).pipe(
			map((response) => {
				User.buildUsersPictureUrls(response.body.content);

				Department.generateDisplayNames(response.body.content.map(x => x.department));
				Department.generateHtmlDisplayNames(response.body.content.map(x => x.department));

				return response;
			})
		);
	}

	public getUsers(options: RequestOptions<User>): Observable<HttpResponse<PagedResourceInterface<User>>> {
		if (!options.size) {
			options.size = 99999;
		}

		return this.http.get(this.buildUrl(options), ['auth']).pipe(
			map((response) => {
				User.buildUsersPictureUrls(response.body.content);

				Department.generateDisplayNames(response.body.content.map(x => x.department));
				Department.generateHtmlDisplayNames(response.body.content.map(x => x.department));

				return response;
			})
		);
	}

	public getTerminatedUsers(options: RequestOptions<User>): Observable<HttpResponse<PagedResourceInterface<User>>> {
		options.getParams = options.getParams || {};
		if (!options.getParams.lookUpConfigs) {
			options.getParams.lookUpConfigs = false;
		}

		return this.http.get(this.buildUrl(options, '/terminated'), ['auth']).pipe(
			map((response: HttpResponse<PagedResourceInterface<User>>) => {
				Department.generateDisplayNames(response.body.content.map(x => x.department));
				Department.generateHtmlDisplayNames(response.body.content.map(x => x.department));
				User.buildUsersPictureUrls(response.body.content);

				return response;
			})
		);
	}

	public search(options: RequestOptions<User>): Observable<HttpResponse<any>> {
		options.getParams = options.getParams || {};
		if (!options.getParams.lookUpConfigs) {
			options.getParams.lookUpConfigs = false;
		}

		return this.http.get(this.buildUrl(options, '/search'), ['auth']);
	}

	public exportCsv(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/export/csv'), options.data, ['auth', 'blob']);
	}

	public patchProfileData(options: RequestOptions<User>): Observable<HttpResponse<any>> {
		return this.http.patch(this.buildUrl(options, '/profile'), options.data, ['auth']);
	}

	public getHierarchy(): Observable<User[]> {
		return this.http.get(`${environment.apiUrl}/org-chart/hierarchy`, ['auth']).pipe(
			map((response: HttpResponse<any>) => {
				const users = response.body;
				User.buildUsersPictureUrls(users);

				users.forEach(user => {
					// TODO Remove this mapping once the API returns the position as an object
					user.position = {name: user.position};

					if (user.dottedLinesAbove) {
						user.dottedLinesAbove = user.dottedLinesAbove.map(x => x.id);
					}

					if (user.dottedLinesBelow) {
						user.dottedLinesBelow = user.dottedLinesBelow.map(x => x.id);
					}
				});

				return users;
			})
		);
	}

	public getHistory(options: RequestOptions<User>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/history'), ['auth']);
	}

	public getRoot(options: RequestOptions<User>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/root'), ['auth']);
	}

	// TODO Put params into data
	public setRoot(id: string, oldReportsToNew: boolean, options: RequestOptions<User>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/root'),
			{
				id: id,
				oldReportToNew: oldReportsToNew
			}, ['auth']);
	}

	public invite(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/invite'), options.data, ['auth']);
	}

	public uninvite(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/invite/cancel'), options.data, ['auth']);
	}

	public getInviteStatus(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/invite/info'), ['auth']);
	}

	public inviteAll(options: RequestOptions<User>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/invite-all'), {}, ['auth']);
	}

	public autoInvite(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.patch(this.buildUrl(options, '/auto-invite'), {}, ['auth']);
	}

	public getAutoInvite(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/auto-invite/settings'), ['auth']);
	}

	public getNewUsers(options: RequestOptions<User>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/newest'), ['auth'])
			.pipe(
				map((response) => {
					if (options.expand && options.expand.indexOf('department') > -1) {
						Department.generateDisplayNames(response.body.content.map(x => x.department));
						Department.generateHtmlDisplayNames(response.body.content.map(x => x.department));
					}

					return response;
				})
			);
	}

	public searchBy(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/search-for'), options.data, ['auth']);
	}

	public getUsersWithAnniversaries(options: RequestOptions<User>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/anniversary'), ['auth']);
	}

	public getFiltersObjectsForUsersWithAnniversaries(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/anniversary-filter'), ['auth']);
	}

	public getSavedFiltersObjectsForUsersWithAnniversaries(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/save-anniversary-filter'), ['auth']);
	}

	public saveFiltersObjectsForUsersWithAnniversaries(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/save-anniversary-filter'), options.data, ['auth']);
	}

	public getFiltersObjectsForUsersWithBirthdays(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/birthday-filter'), ['auth']);
	}

	public getSavedFiltersObjectsForUsersWithBirthdays(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/save-birthday-filter'), ['auth']);
	}

	public saveFiltersObjectsForUsersWithBirthdays(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/save-birthday-filter'), options.data, ['auth']);
	}

	public getUsersWithBirthdays(options: RequestOptions<User>): Observable<HttpResponse<any>> {
		return this.http.get(this.buildUrl(options, '/birthday'), ['auth']);
	}

	public saveSocialLinks(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/social-links'), options.data, ['auth']);
	}

	public assignBadges(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl(options, '/badges/assign'), options.data, ['auth']);
	}

	public deleteUserPicture(options: RequestOptions<User>): Observable<HttpResponse<any>> {
		return this.http.delete(this.buildUrl(options, '/avatars'), ['auth']);
	}

	public downloadAttachment(userId: string, attachmentId: string): Observable<HttpResponse<any>> {
		return this.http.get(`${environment.apiUrl}/users/${userId}/attachments/${attachmentId}/download`, ['auth', 'blob']);
	}

	public exportUsersRolesToCsv(): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl({}, '/acl/list/download/csv'), null, ['auth', 'blob']);
	}

	public exportChangeLogToCsv(userId: string): Observable<HttpResponse<any>> {
		return this.http.post(this.buildUrl({}, `/${userId}/history/download/csv`), null, ['auth', 'blob']);
	}

	public getCustomFields(userId: string): Observable<CustomField[]> {
		return this.http.get(this.buildUrl({}, `/${userId}/custom-fields`), ['auth'])
			.pipe(pluck('body'));
	}

	public updateFieldValue(userId: string, key: string, value: any): Observable<any> {
		return this.http.patch(this.buildUrl({}, `/${userId}/custom-fields/${key}`), {value}, ['auth']);
	}

	private mutateExpands(response: User): void {
		if (!response.skills) {
			response.skills = [];
		}

		if (!response.hobbies) {
			response.hobbies = [];
		}

		if (!response.desiredPositions) {
			response.desiredPositions = [];
		}

		if (!response.desiredSkills) {
			response.desiredSkills = [];
		}
	}
}
