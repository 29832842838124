import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationSetup } from '@app/register/models/organization-setup.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AlertsService } from '@helloteaminc/front-common';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { finalize, map, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthOptions } from '../../auth/models/auth-options.model';
import { Department } from '../../main/organization/departments/models/department.model';
import { AppGlobals } from '../common/app.globals';
import { ErrorResponse } from '../common/error-response.model';
import { HttpInterceptor } from '../http/http.interceptor';
import { RequestOptions } from '../interfaces/request-options.interface';
import { CommonUtil } from '../utils/common.util';
import { BaseService } from './base.service';
import { MessagesService } from './messages.service';
import { NotificationMessagesService } from './notification-messages.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService extends BaseService<any, RequestOptions<any>> {

	private logoutInProgress: boolean;

	private readonly jwtHelperService = new JwtHelperService();

	constructor(
		protected http: HttpInterceptor,
		private cookieService: CookieService,
		private router: Router,
		private messagesService: MessagesService,
		private notificationMessagesService: NotificationMessagesService
	) {
		super(http, '/auth');
	}

	getLogoutInProgress(): boolean {
		return this.logoutInProgress;
	}

	setLogoutInProgress(value: boolean): void {
		this.logoutInProgress = value;
	}

	login(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options), options.data, []);
	}

	register(data: OrganizationSetup): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl({}, '/register'), data, []);
	}

	logout(): void {
		this.http.post(super.buildUrl({}, '/logout'), null, ['auth'])
			.pipe(finalize(() => this.logoutInProgress = false))
			.subscribe({
				next: () => this.logoutCallback(),
				error: () => this.logoutCallback()
			});
	}

	signOut(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/sign-out'), options.data, ['auth']);
	}

	signOutOtherSessions(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/sign-out-other-sessions'), options.data, ['auth']);
	}

	verifyEmail(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/verify'), options.data, ['auth']);
	}

	changePassword(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/password/change'), options.data, ['auth']);
	}

	setPassword(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/password/set'), options.data, []);
	}

	resetPassword(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/password/reset'), options.data, []);
	}

	generatePassword(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/password/generate'), options.data, []);
	}

	confirmPasswordReset(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/password/confirm'), options.data, []);
	}

	resendVerificationEmail(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/verify/resend'), options.data, []);
	}

	getAuthOptions(options: RequestOptions<any>): Observable<AuthOptions> {
		return this.http.get(super.buildUrl(options), []).pipe(
			map((response) => response.body)
		);
	}

	getLoginOptions(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.get(super.buildUrl(options, '/options'), ['auth']);
	}

	setAzureTenant(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/options/azure/tenant'), options.data, ['auth']);
	}

	setLdapOptions(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/options/ldap'), options.data, ['auth']);
	}

	removeLdapOptions(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/options/ldap/clear'), options.data, ['auth']);
	}

	testLdap(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/options/ldap/test'), options.data, ['auth']);
	}

	setLoginOptions(options: RequestOptions<any>): Observable<HttpResponse<any>> {
		return this.http.post(super.buildUrl(options, '/options'), options.data, ['auth']);
	}

	requestUserProfile(): Observable<HttpResponse<any>> {
		return this.http.get(environment.apiUrl + '/me', ['auth'])
			.pipe(
				tap((response) => {
					if (AppGlobals.getLoggedUser()) {
						const currentComponents = AppGlobals.getLoggedUser().components || [];
						const newComponents = response.body.components || [];

						if (currentComponents.sort().join(',') !== newComponents.sort().join(',')) {
							CommonUtil.updateNavigation();
							AlertsService.showInfoNotice('Permissions updated', 'Your permissions have been updated.');
						}
					}

					Department.generateDisplayName(response.body.department);
					Department.generateHtmlDisplayName(response.body.department);

					AppGlobals.setLoggedUser(response.body);

					if (response.body.user) {
						AppGlobals.personalAvatarUpdatedSubject.next(response.body.user.profilePicture);
					}
				})
			);
	}

	getAccessToken(): string {
		return localStorage.getItem('access_token');
	}

	isAccessTokenExpired(): boolean {
		const token = this.getAccessToken();

		if (!token) {
			return true;
		}

		return this.jwtHelperService.isTokenExpired(token);
	}

	setAccessToken(token: string): void {
		localStorage.setItem('access_token', token);
	}

	removeAccessToken(): void {
		localStorage.removeItem('access_token');
	}

	getRefreshToken(): string {
		return localStorage.getItem('refresh_token');
	}

	isRefreshTokenExpired(): boolean {
		const token = this.getRefreshToken();

		if (!token) {
			return true;
		}

		return this.jwtHelperService.isTokenExpired(token);
	}

	setRefreshToken(token: string): void {
		localStorage.setItem('refresh_token', token);
	}

	removeRefreshToken(): void {
		localStorage.removeItem('refresh_token');
	}

	redirectAfterLogin(): void {
		const redirectUrl = localStorage.getItem('redirectUrl') || '/';
		this.router.navigateByUrl(redirectUrl, { state: { navigatedFromLogin: true } }).then(() => {
			localStorage.removeItem('redirectUrl');
		});
	}

	showLoginError(response): void {
		let message = 'Invalid email or password.';

		if (response?.error && response?.error[0].message) {
			message = response.error[0].message;
		}

		if (response?.error && response?.error[0].code === 'login.account-not-verified') {
			message = 'You have not verified your email address! Request a new email <strong class="request-verify-token">here</strong>';
		}

		this.messagesService.setErrorMessages([message]);
	}

	checkUserTimezone(): void {
		if (this.cookieService.get('time-zone-check') || !AppGlobals.getLoggedUser().name) {
			return;
		}

		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const offset = moment.tz(timezone).format('Z');
		const preferencesOffset = moment().format('Z');

		if (offset !== preferencesOffset) {
			AlertsService.showInfoNotice('Time zone', 'Your timezone is different from the timezone in your preference settings.', false, {
				confirm: true,
				buttons: [
					{
						text: 'Change',
						addClass: 'button button-primary-outline button-tiny',
						click: (notice) => {
							this.router.navigate(['account', 'preferences']);
							notice.remove();
						}
					},
					{
						text: 'Cancel',
						addClass: 'button button-unstyled button-tiny',
						click: (notice) => {
							notice.remove();
							this.cookieService.put('time-zone-check', 'true', {
								expires: new Date(moment().add(1, 'years').format('YYYY-MM-DD'))
							});
						}
					}
				]
			});
		}
	}

	checkUserNotificationMessage(): void {
		this.notificationMessagesService.getMessage().pipe(
			take(1)
		).subscribe((response) => {
			let message = response.body;
			AlertsService.showRichInfoInfoNotice('', message.text, {
				confirm: true,
				buttons: [
					{
						text: 'OK',
						addClass: 'button button-primary-outline button-tiny',
						click: (notice) => {
							this.notificationMessagesService.markAsSeen({
								id: message.id
							}).pipe(take(1)).subscribe(() => {
							}, res => {
								AlertsService.showErrorNotice('Error', ErrorResponse.getFirstError(res).message);
							});
							notice.remove();
						}
					}
				]
			});
		}, res => {
			if (res.code === 404) {
				return;
			}
			AlertsService.showErrorNotice('Error', ErrorResponse.getFirstError(res).message);
		});
	}

	private logoutCallback(): void {
		this.resetImportProgress();

		this.removeAccessToken();
		this.removeRefreshToken();
		this.redirectToAuth();
	}

	private resetImportProgress(): void {
		if (this.cookieService.get('import-progress')) {
			this.cookieService.remove('import-progress');
		}
	}

	private redirectToAuth(): void {
		if (localStorage.getItem('impersonationURL')) {
			const impersonationURL = localStorage.getItem('impersonationURL');
			localStorage.removeItem('impersonationURL');
			window.open(impersonationURL, '_blank');
		}
		location.replace(`${location.origin}/auth`);
	}

}
